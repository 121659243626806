// 检测记录
<template>

    <div class='testRecord baseBg'>
        <div class="searchBar ofh">
            <el-form :model="searchForm"
                     style="width: 100%"
                     ref="searchForm"
                     class="fll"
                     :inline="true"
                     size="normal">
                <el-form-item label="设备编号"
                              prop="qrCode">
                    <el-input v-model="searchForm.qrCode"
                              placeholder="请输入设备编号"
                              clearable></el-input>
                </el-form-item>
                <el-form-item label="设备类型"
                              prop="empTypeId">
                    <selectDeviceType placeholder="请选择设备类型"
                                      v-model="searchForm.empTypeId"
                                      @change="searchForm.empTypeModelId = ''"
                                      @input="(data) => searchForm.empTypeId=data"></selectDeviceType>
                </el-form-item>
                <el-form-item label="规格型号"
                              prop="empTypeModelId">
                    <selectEmpTypeModel v-model="searchForm.empTypeModelId"
                                        :disabled="searchForm.empTypeId ? false : true"
                                        :searchData="{empTypeId:searchForm.empTypeId}"
                                        @input="(data) => searchForm.empTypeModelId=data"></selectEmpTypeModel>
                </el-form-item>
                <el-form-item label="检测类型"
                              prop="detectType">
                    <selctMenu code="DetectType"
                               v-model="searchForm.detectType"
                               @input="(data) => searchForm.detectType = data"></selctMenu>
                </el-form-item>
                <el-form-item label="检测时间"
                              prop="startTime">
                    <el-date-picker v-model="timeArr"
                                    type="datetimerange"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="yyyy-MM-dd HH:mm:ss"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"></el-date-picker>
                </el-form-item>
                <el-form-item label="检测结果"
                              prop="result">
                    <selctMenu code="DetectResult"
                               v-model="searchForm.result"
                               @input="(data) => searchForm.result = data"></selctMenu>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary"
                               @click="getList">搜索
                    </el-button>
                </el-form-item>
                <el-form-item>
                    <el-button type=""
                               @click="resetSearchForm">清除
                    </el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="listBox">
            <div class="tableBox">
                <el-table :data="tableData.data"
                          border
                          :height="tableHeight"
                          stripe>
                    <el-table-column prop="qrCode"
                                     label="设备编号"
                                     align="left"
                                     min-width="120"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="empType"
                                     label="设备类型"
                                     align="left"
                                     min-width="120"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="provider"
                                     label="供应商"
                                     align="left"
                                     min-width="120"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="empTypeModel"
                                     label="规格型号"
                                     align="left"
                                     min-width="120"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="belongDeptName"
                                     label="归属部门"
                                     align="left"
                                     min-width="120"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="serialNo"
                                     label="SN码"
                                     align="left"
                                     min-width="120"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="detectType.text"
                                     label="检测类型"
                                     align="left"
                                     min-width="120"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="detectTime"
                                     label="检测时间"
                                     align="left"
                                     min-width="160"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="userName"
                                     label="检测人员"
                                     align="left"
                                     min-width="120"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="result.text"
                                     label="检测结果"
                                     align="left"
                                     min-width="120"
                                     width="auto">
                    </el-table-column>
                    <el-table-column prop="id"
                                     label="操作"
                                     min-width="100">
                        <template slot-scope="scope">
                            <el-link type="primary"
                                     @click="openpopTestDetails(scope.row)">查看
                            </el-link>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <Pagination :page="searchForm.page"
                            :limit="searchForm.size"
                            :total="pageTotal"
                            @pagination="paginationChange" />
            </div>
        </div>
        <!-- 检测详情 -->
        <PopTestDetails v-if="popTestDetailsVisible"
                        :detectId="editInfo.id"
                        :detectionType="editInfo.detectType.text || ''"
                        @close="closepopTestDetails"></PopTestDetails>
    </div>

</template>

<script>
import PopTestDetails from "./components/popTestDetails";
import { Loading } from "element-ui";
import Pagination from "@/components/Pagination"; // 分页
// import API from "@/api/hardware.js";
import API from "@/api/safeguardManage.js";
import selectDeviceType from "@/components/commonSelect/selectEmpType.vue";
import selectEmpTypeModel from "@/components/commonSelect/selectEmpTypeModel.vue";
import selctMenu from "@/components/commonSelect/selctMenu.vue";

export default {
    name: "testRecord",

    props: [],

    components: {
        PopTestDetails,
        Pagination,
        selectDeviceType,
        selectEmpTypeModel,
        selctMenu,
    },

    data() {
        return {
            // 筛选表单
            searchForm: {
                qrCode: "", // 设备编号
                empTypeId: "", // 设备类型
                empTypeModelId: "", // 规格类型id
                detectType: "", // 检测类型，
                detectStartTime: "",
                detectEndTime: "",
                result: "", //检测结果
                page: 1,
                size: 20,
            },
            timeArr: [], // 检测时间

            pageTotal: 0, // 分页总数
            tableData: {
                data: [],
            },
            editInfo: null, // 编辑详情
            popTestDetailsVisible: false, // 检测详情弹窗显示
        };
    },

    created() {},

    /* 组件缓存触发 */
    activated() {
        // 检测记录 列表
        // this.getList();
    },

    mounted() {
        // 检测记录 列表
        this.getList();
    },

    methods: {
        // 检测记录 列表
        getList() {
            let loading = Loading.service({
                target: document.querySelector(".tableBox"),
            });
            API.getTestRecord(
                Object.assign({}, this.searchForm, {
                    detectStartTime: this.timeArr[0] || "",
                    detectEndTime: this.timeArr[1] || "",
                })
            )
                .then((res) => {
                    this.tableData.data = res.content.records;
                    this.pageTotal = res.content.total;
                })
                .finally(() => {
                    loading.close();
                });
        },

        // 改变分页
        paginationChange(e) {
            this.searchForm.size = e.limit;
            this.searchForm.page = e.page;
            this.getList();
        },

        //清空筛选条件
        resetSearchForm() {
            this.$nextTick(() => {
                if (this.$refs["searchForm"] !== undefined) {
                    this.$refs["searchForm"].resetFields();
                    this.timeArr = [];
                    // 查询列表
                    this.getList();
                }
            });
        },
        // 检测详情 打开弹框
        openpopTestDetails(row = null) {
            this.editInfo = row;
            this.popTestDetailsVisible = true;
        },
        // 检测详情 关闭弹框
        closepopTestDetails() {
            this.editInfo = null;
            this.popTestDetailsVisible = false;
        },
    },

    computed: {
        tableHeight() {
            return this.commonJs.getTableHeight({ pageOtherHeight: 305 });
        },
    },

    watch: {},
};
</script>

<style lang='scss' scoped>
</style>
