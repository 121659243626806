// 检测详情
// popTestDetails
<template>

    <div class="popTestDetails">
        <el-dialog title="检测详情"
                   :close-on-click-modal="false"
                   :visible.sync="dialogVisible"
                   :modal-append-to-body="true"
                   :append-to-body="true"
                   v-loading="isloading"
                   width="760px"
                   :before-close="close">

            <div class="headInfo">
                <div class="headPicBox">
                    <el-image style="width: auto; height: 100%"
                              fit="scale-down"
                              :src="`${PATH}/file/view/?file=${detailInfo.headPic}&access-token=${token}`" />
                </div>
                <div class="mainInfo">
                    <div class="name">{{ detailInfo.userName || '' }}</div>
                    <div class="content">部门：{{ detailInfo.belongDeptName || '' }} |
                        日期：{{ detailInfo.detectTime || '' }}
                    </div>
                </div>
                <div class="tipBox"
                     v-if="detailInfo.result">
                    <div class="tip green"
                         v-if="detailInfo.result.name === 'PASS'">{{detailInfo.result.text}}</div>
                    <div class="tip red"
                         v-if="detailInfo.result.name === 'FAIL'">{{detailInfo.result.text}}</div>
                </div>
            </div>

            <el-row v-if="detectionType === '能力检测'">
                <div class="testList mb20"
                     v-for="(item,idx) in detailInfo.data"
                     :key="idx">
                    <div class="title">{{ item.key || '' }}</div>
                    <div class="item"
                         v-for="(it,i) in item.value"
                         :key="i">
                        <div class="label">{{ it.detectType || '' }}</div>
                        <div v-if="it.result.text === '不需要测试'"
                             class="status">不需要测试</div>
                        <div v-if="it.result.text === '通过'"
                             class="status green">测试通过</div>
                        <div v-if="it.result.text === '不通过'"
                             class="status red">测试不通过</div>
                    </div>
                </div>
            </el-row>

            <div class="testList mb20"
                 v-if="detectionType === '硬件检测'">
                <div class="title">硬件能力</div>
                <div class="item"
                     v-for="(item,idx) in detailInfo.data"
                     :key="idx">
                    <div class="label">{{ item.detectType || '' }}</div>
                    <div v-if="item.result.text === '不需要测试'"
                         class="status">不需要测试</div>
                    <div v-if="item.result.text === '通过'"
                         class="status green">测试通过</div>
                    <div v-if="item.result.text === '不通过'"
                         class="status red">测试不通过</div>
                </div>
            </div>

        </el-dialog>

    </div>
</template>

<script>
import API from "@/api/safeguardManage.js";

export default {
    name: "popTestDetails",

    props: {
        // 编辑详情
        editInfo: {
            type: Object,
            default: null,
        },
        //
        detectId: {
            type: String,
            default: "",
        },

        // 检测类型 硬件检测 能力检测
        detectionType: {
            type: String,
            default: "",
        },
    },

    components: {},

    data() {
        return {
            dialogVisible: true,
            isloading: false,
            detailInfo: {
                result: {},
                data: [],
            },
            PATH: window.configPath.baseUrl,
            token: this.commonJs.getCookie("ERPToken"),
        };
    },

    created() {},

    mounted() {},

    methods: {
        // 获取详情
        getDetail() {
            this.isloading = true;
            API.getMatterRecord(this.detectId)
                .then((res) => {
                    this.detailInfo = res.content;
                })
                .finally(() => {
                    this.isloading = false;
                });
        },
        // 关闭弹窗
        close() {
            this.$emit("close");
        },
    },

    computed: {},

    watch: {
        detectId: {
            deep: true,
            immediate: true,
            handler(newValue) {
                newValue && this.getDetail();
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.headInfo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 32px;

    .headPicBox {
        width: 64px;
        height: 64px;
        background: #ffffff;
        box-shadow: 0px 2px 3px rgba(114, 123, 129, 0.28);
        border-radius: 50%;
        margin-right: 20px;
        overflow: hidden;
        text-align: center;
    }

    .mainInfo {
        flex: 1;

        .name {
            font-size: 20px;
            line-height: 26px;
            color: #333333;
            margin-bottom: 11px;
        }

        .content {
            font-size: 14px;
            line-height: 19px;
            color: #666666;
        }
    }

    .tipBox {
        width: 100px;
        text-align: right;

        .tip {
            padding: 0 8px;
            background: rgba(92, 189, 24, 0.2);
            border: 1px solid #5cbd18;
            border-radius: 2px;
            font-size: 12px;
            line-height: 18px;
            color: #5cbd18;
        }

        .tip.red {
            color: #fd5656;
            background: rgba(253, 86, 86, 0.2);
        }
    }
}

.testList {
    background: #ffffff;
    border: 1px solid #ebeef5;

    .title {
        font-size: 16px;
        line-height: 36px;
        color: #333333;
        background: #e8f2fa;
        border-radius: 0px;
        padding: 0 20px;
    }

    .item {
        padding: 15px 20px;
        line-height: 19px;
        border-bottom: 1px solid #ebeef5;
        display: flex;

        .label {
            font-size: 14px;
            color: #333333;
            flex: 1;
        }

        .status {
            text-align: right;
            width: 100px;
            font-size: 14px;
            color: #999999;
        }

        .red {
            color: #fd5656;
        }

        .green {
            color: #5cbd18;
        }
    }

    .item:last-child {
        border-bottom: 0;
    }
}
</style>